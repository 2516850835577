import { isJsonStr } from "../general-utils/generalUtils"
import { setDisplayProjects } from "../store/GenericReducer"


const buildFiltersFromProjects = (projects, apiFilters, partner) => {
    let city = []
    let volunteerFields = []
    let population = []
    let commitment = []
    apiFilters?.map(filter => {
        projects.map(project => {
            if(filter.filterTypeID === 1){ // city
                project.addresses && Array.isArray(project.addresses) && project.addresses.map(item => {city.push(item)})
            }
            if(filter.filterTypeID === 6){ // VolunteerFields
                project.volunteerFields && Array.isArray(project.volunteerFields) && project.volunteerFields.map(item => {volunteerFields.push(item)})
            }
            if(filter.filterTypeID === 7 || filter.filterTypeID === 9){ // Population / commitment 
                let filterDetails = isJsonStr(filter.filterDetails) ? JSON.parse(filter.filterDetails) : false
                let volunteerDetailsOptionsID = filterDetails ? filterDetails.volunteerDetailsOptionsID : false
                let index = partner.volunteerDetailsOptionsDictionary ? partner.volunteerDetailsOptionsDictionary.findIndex(item => item.volunteerDetailsOptionsID === volunteerDetailsOptionsID) : -1 // population
                if(index !== -1){
                    let filterDictionary = partner.volunteerDetailsOptionsDictionary[index].answerOptions
                    if(project.projectAnswersOption){
                        let projectIndex = project.projectAnswersOption.findIndex(item => item.volunteerDetailsOptionsID === volunteerDetailsOptionsID)
                        let filterIds = projectIndex !== -1 && project.projectAnswersOption[projectIndex].answer
                        if(filterIds){
                            let idsArray = filterIds.split(",")
                            filterDictionary.map(item => {
                                if(idsArray.includes(item.volunteerOptionID.toString())){ 
                                    filter.filterTypeID === 7 && population.push(item)
                                    filter.filterTypeID === 9 && commitment.push(item)
                                }
                            })
                        }
                    }
                }
            }
        })
    })

    const uniqueCity = getArrayUniqueValues(city, 'cityID')
    // console.log(uniqueCity)

    const uniqueCommitment = getArrayUniqueValues(commitment, 'volunteerOptionID')
    //console.log("uniqueCommitment-> ", uniqueCommitment)

    const uniquePopulation = getArrayUniqueValues(population, 'volunteerOptionID')
    //console.log("uniquePopulation->> ", uniquePopulation)

    const uniqueVolunteerFields = getArrayUniqueValues(volunteerFields, 'volunteerFieldID')
    //console.log("volunteerFields->> ", uniqueVolunteerFields)

    let finalObj = {
        commitment: uniqueCommitment,
        population: uniquePopulation,
        volunteerFields: uniqueVolunteerFields,
        city: uniqueCity
    }
    return finalObj
}

const getArrayUniqueValues = (array, id) => {
    let uniqueArray = array.filter((obj, index, self) => 
        index === self.findIndex((t) => (t[id] === obj[id]))
    )
    uniqueArray.sort((a, b) => a[id] - b[id])
    return uniqueArray
}

const isEmpty = (obj) => {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }
  
    return true;
}

const filterProjects = (store, dispatch) => {
    let filters = store.partner.partnerConfig.filterTypes
    let projects = [...store.allProjects]
    let city = []
    let volunteerFields = []
    let population = []
    let commitment = []
    filters?.map(filter => {
        projects.map(project => {
            if (filter.filterTypeID === 1 && 'addresses' in project && Array.isArray(project.addresses)){ // city          
                project.addresses.map(address => {
                    if(store.filterChoices[filter.filterTypeID].choice){                             
                        if(address.cityID === store.filterChoices[filter.filterTypeID].choice.cityID){
                            city.push(project.projectID)
                        }
                    }
                })                     
            }

            if(filter.filterTypeID === 6 && 'volunteerFields' in project && Array.isArray(project.volunteerFields)){ // VolunteerFields
                project.volunteerFields.map(field => {
                    if(store.filterChoices[filter.filterTypeID].choice){                             
                        if(field.volunteerFieldID === store.filterChoices[filter.filterTypeID].choice.volunteerFieldID){
                            volunteerFields.push(project.projectID)
                        }
                    }
                }) 
            }

            if(filter.filterTypeID === 7 || filter.filterTypeID === 9){ // Population / commitment
                if('projectAnswersOption' in project && Array.isArray(project.projectAnswersOption)){ 
                    let filterDetails = isJsonStr(filter.filterDetails) ? JSON.parse(filter.filterDetails) : false
                    let volunteerDetailsOptionsID = filterDetails ? filterDetails.volunteerDetailsOptionsID : false
                    let index = store.partner.volunteerDetailsOptionsDictionary ? store.partner.volunteerDetailsOptionsDictionary.findIndex(item => item.volunteerDetailsOptionsID === volunteerDetailsOptionsID) : -1
                    if(index !== -1){                 
                        if(project.projectAnswersOption){
                            let projectIndex = project.projectAnswersOption.findIndex(item => item.volunteerDetailsOptionsID === volunteerDetailsOptionsID)
                            let filterIds = projectIndex !== -1 && project.projectAnswersOption[projectIndex].answer
                            if(filterIds){                           
                                let idsArray = filterIds.split(",")                            
                                if(store.filterChoices[filter.filterTypeID].choice){       
                                    if(idsArray.includes((store.filterChoices[filter.filterTypeID].choice.volunteerOptionID).toString())){                      
                                        filter.filterTypeID === 7 && population.push(project.projectID)
                                        filter.filterTypeID === 9 && commitment.push(project.projectID)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        })
    })
    // console.log('all projects -> ', projects)
    // console.log('city -> ', city)
    // console.log('commitment -> ', commitment)
    // console.log('population -> ', population)
    // console.log('volunteerFields -> ', volunteerFields)
    let filteredProjectsIds = findCommonValues(city, volunteerFields, commitment, population)
    let testForEmptyChoices = false
    Object.entries(store.filterChoices).forEach(([key, value]) => {
        if(value.choice){
            testForEmptyChoices = true
        }
    })
    let filteredProjects = filteredProjectsIds.length > 0 ? projects.filter(x => filteredProjectsIds.includes(x.projectID)) : []
    testForEmptyChoices ? dispatch(setDisplayProjects(filteredProjects)) : dispatch(setDisplayProjects(store.allProjects))
}

const findCommonValues = (...arrays) => {
    const nonEmptyArrays = arrays.filter(arr => arr.length > 0)
    if (nonEmptyArrays.length === 0) return [];
  
    return nonEmptyArrays[0].filter(value => 
        nonEmptyArrays.every(arr => arr.includes(value))
    );
}

export { buildFiltersFromProjects, getArrayUniqueValues, isEmpty, filterProjects }